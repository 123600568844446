import axios from 'axios';
import store from '../store';

const baseURL = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : ''}/${process.env.REACT_APP_API_BASE}`;

const instance = axios.create({
    baseURL,
});

instance.interceptors.request.use(config => {
    try {
        const accessToken = store.getState().user.accessToken;
        if (accessToken && !config.headers.authorization) config.headers.authorization = `Bearer ${accessToken}`;

        const url = config.url;
        if (!url[url.length - 1] === '/') config.url = `${config.url}/`;

        return config;
    } catch (err) {
        console.log(err.message);
    }
});

instance.defaults.headers.common['Content-Type'] = 'application/json';
instance.defaults.headers.common.Accept = 'application/json';

export default instance;
