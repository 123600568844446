export default class Endpoints {
  static token = '/token/';
  static refreshToken = '/token/refresh/';
  static recoveryPassword = '/auth/users/reset_password/';
  static resetPassword = '/auth/users/reset_password_confirm/';
  static users = '/user_info';
  static user_reset_password = '/user_reset_password/';
  static user_emails = '/emails';
  static user_email = '/user_email/';
  static proposals = '/proposals';
  static proposal_notes = '/proposal_notes';
  static proposals_info = '/proposals_info';
  static proposals_count = '/proposals_count';
  static proposals_export = '/proposals_export';
  static messages = '/messages';
  static messages_send_mail = '/messages_send_mail';
  static products_files = '/products_files';
  static files = '/files';
  static insurances = '/insurance';
  static insurance_info = '/insurance_info';
  static terms_of_use = '/terms_of_use';
  static companies = '/companies';
  static products = './products';
  static product_companies = './product_companies';
  static liveness = '/liveness';
  static certificates = '/certificates';
  static clients = '/clients';
  static chat_bot_hours = '/chat_bot_hours';
  static chat_bot_config = '/chat_bot_config';
  static holiday = '/holidays_config';
  static services = '/services';
  static services_export = '/services_export';
  static new_service = '/new_service';
  static credit_card = '/credit_card';
  static credit_card_info = '/credit_card_info';
  static credit_card_export = '/credit_card_export';
}
